import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

import logo from "../assets/img/fusionlogo.png";
import { SessionContext } from "Session";
import { useAuth } from "react-oidc-context";

var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  const location = useLocation();
  const session = useContext(SessionContext);


  console.log(session)
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname == routeName ? "active" : "";
  };

  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebar.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });


  if (session.auth.isAuthenticated && session.clientId) {
    return (
      <div
        className={`sidebar ${props.isSmall ? "small" : ""}`}
        data-color={props.bgColor}
        data-active-color={props.activeColor}
      >
        <div className="logo">
          <a
            href="/"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a
            href="/"
            className="simple-text logo-normal"
          >
            Carrier Connect
          </a>
        </div>
        <div className="sidebar-wrapper" ref={sidebar}>
          <Nav>
            {props.routes.map((prop, key) => {
              if (prop.requiresAdmin && session.settings.datelUser !== "True")
                return (<div />)

              return (
                <li
                  className={
                    activeRoute(prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    {!props.isSmall && <p>{prop.name}</p>}
                  </NavLink>
                </li>
              );
            })}
          </Nav>
          <i className="toggle-button fa fa-bars" onClick={() => props.toggleSize()} />
        </div>
      </div>
    );
  }
}

export default Sidebar;
